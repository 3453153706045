import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import Geocode from "react-geocode";
import fetchOrganizationLatLog from '../../../../Api/fetchOrganizationLatLog';

export default function Map(props) {

  const { t, i18n } = useTranslation();
    
  Geocode.setApiKey("AIzaSyCagARF_1dqM7egF8R19r76XlA_oaJRv8U");

  const [cookies, setCookie] = useCookies(['locale']);
  const [marker, setMarker] = useState({
        latitude:props.content.latitude,        
        longitude:props.content.longitude,        
  });

  let locale = localStorage.getItem("locale") || 'fi';

  var address = props.content;

  let addresses = [
    //props.content.street1.replace(' ', '+'),
    //props.content.postcode,
    //props.content.city,
    
  ].join('+');

    console.log(address);
  if(marker.latitude == '' && marker.longitude == ''){
     var address =  address.careOf+' '+address.street1+' '+address.city+' '+address.state+' '+address.postcode;
             console.log(address);
     
        Geocode.fromAddress(address).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
             var latitude =  lat;
             var longitude = lng;
             setMarker({latitude:lat,longitude:lng});
             console.log(latitude);
             console.log(longitude);

            fetchOrganizationLatLog(props.content.id,latitude,longitude)
              .then(response => response.json())
              .then(json => {

              });
              
          },
          (error) => {

          }
        );
  }
  
  var mapQuery = "https://maps.google.com/maps?q="+marker.latitude+","+marker.longitude+"&hl="+locale+"&zoom=6&amp&output=embed";
  if(marker.latitude != '' && marker.longitude != ''){
    return(
      <div className="organization__map">
        <h3 className="screen-reader-text">{t('map')}</h3>
        <iframe
          width="100%"
          className="map-ifram"
          frameBorder="0"
          scrolling="no"
          loading="lazy"
          src={mapQuery}></iframe>
      </div>
    );
  }

  return ('');
}
